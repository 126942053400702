const FacebookIcon = (): JSX.Element => {
  return (
    <div>
      <svg
        width="11"
        height="21"
        viewBox="0 0 11 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1543 11.6914L10.7098 8.07187H7.23672V5.72305C7.23672 4.73281 7.72188 3.76758 9.27734 3.76758H10.8562V0.685938C10.8562 0.685938 9.42344 0.441406 8.05352 0.441406C5.19336 0.441406 3.32383 2.175 3.32383 5.31328V8.07187H0.144531V11.6914H3.32383V20.4414H7.23672V11.6914H10.1543Z"
          fill="#10247E"
        />
      </svg>
    </div>
  );
};

export default FacebookIcon;
